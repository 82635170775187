import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

export default function NotificationPopup() {
  const [isVisible, setIsVisible] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 6000); // Show after 6 seconds
    return () => clearTimeout(timer);
  }, []);

  const handleNavigate = () => {
    setIsPressed(true);
    setTimeout(() => {
      setIsVisible(false);
      navigate('/contact');
    }, 200); // Delay for press effect animation
  };

  const handleDismiss = () => {
    setIsVisible(false);
  };

  interface DragInfo {
    offset: {
      x: number;
    };
  }

  const handleDragEnd = (
    _event: MouseEvent | TouchEvent | PointerEvent,
    info: DragInfo
  ) => {
    if (info.offset.x > 100) {
      // Adjust the threshold as needed
      handleDismiss();
    }
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
          transition={{ type: 'spring', stiffness: 500, damping: 30 }}
          className="fixed bottom-20 right-0 m-4 p-4 bg-white rounded-l-lg shadow-lg z-40"
          drag="x"
          onDragEnd={handleDragEnd}
        >
          <motion.div
            className="flex items-center justify-between"
            animate={isPressed ? { scale: 0.95 } : { scale: 1 }}
            transition={{ duration: 0.1 }}
          >
            <div onClick={handleNavigate} className="cursor-pointer flex-grow">
              <p className="text-primary">
                New customers get <strong>15% off</strong>! Returning customers
                get <strong>30% off</strong>!
              </p>
            </div>
            <button
              onClick={handleDismiss}
              className="ml-4 text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              {/* Flipped arrow icon */}
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M9 5l7 7-7 7"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </motion.div>
          <div className="mt-2 text-sm text-gray-500 text-right">
            <p>Swipe away to dismiss</p>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

import { useEffect, useRef } from 'react';
import { useAnimation, useInView } from 'framer-motion';
import Review from './review';

export default function Reviews() {
  const controls = useAnimation();
  const ref = useRef(null);
  const ref_2 = useRef(null);
  const isInView = useInView(ref, {
    margin: '-20%', // top, right, bottom, left
  });
  const isInView_2 = useInView(ref_2, {
    margin: '0px 0px 0px 0px', // top, right, bottom, left
  });

  useEffect(() => {
    if (isInView) {
      controls.start({ opacity: 1, x: 0 });
    }
  }, [controls, isInView]);

  useEffect(() => {
    if (isInView_2) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [controls, isInView_2]);

  return (
    <section ref={ref_2} className="flex justify-center items-center pt-10">
      <div className="px-4 sm:px-6 xl:mr-0 xl:pl-8 xl:pr-0 py-8">
        <div className="grid grid-cols-1 gap-y-6 xl:grid-cols-3 xl:items-center xl:gap-x-16">
          <div className="text-center sm:text-left bg-primary text-secondary rounded-lg pl-2">
            <h2 className="text-[3rem] sm:text-[3rem] font-bold tracking-tight text-start max-w-[80%]">
              Our Testimony
            </h2>
          </div>

          <div className="-mx-6 xl:col-span-2 xl:mx-0 px-4">
            <div className="swiper-container !overflow-hidden">
              <div className="swiper-wrapper" ref={ref}>
                <Review
                  author="Joshua Morales"
                  review="I couldn’t be happier with these Christmas lights the Mulroony’s set up for my home!!! They are bright, durable, and create the perfect festive ambiance for the holiday season. The colors are vibrant, and the quality is outstanding. Highly recommend to anyone looking to make their holiday decorations truly shine!!!"
                  quote="“Bright and Festive!”"
                  direction="right"
                  link="https://business.google.com/u/2/n/8455656805652692284/reviews?hl=en"
                />

                <Review
                  author="Vincenzo Brigati"
                  review="I was undecided on how to decorate my home this Christmas and Dylan provided great ideas and made my home come to life for the Holidays. Quick, easy, and professional service for a reasonable price, highly recommend for people that do not have the time to decorate."
                  quote="“Quick, Easy, and Professional!”"
                  direction="left"
                  link="https://business.google.com/u/2/n/8455656805652692284/reviews?hl=en"
                />

                <Review
                  author="Danielle Ragan"
                  review="Mulroony Christmas Lights was able to make my vision come to life! From the roof lighting to the garland and ground lighting, I couldn’t have asked for anything more. Super easy to communicate with and very reasonably priced. Totally recommend!"
                  quote="“Made My Vision Come to Life!”"
                  direction="left"
                  link="https://business.google.com/u/2/n/8455656805652692284/reviews?hl=en"
                />
                <Review
                  author="MB"
                  review="If you're looking to truly get into the Christmas spirit, look no further than Mulroony Christmas Lights! From the moment Dylan arrived at my home to design the perfect display to the installation itself, he was nothing short of professional and courteous. His attention to detail and creativity really brought my vision to life. I wholeheartedly recommend him for all your Christmas lights installation needs—you won’t be disappointed!"
                  quote="“Professional and Courteous!”"
                  direction="left"
                  link="https://business.google.com/u/2/n/8455656805652692284/reviews?hl=en"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import React from 'react';
import { motion } from 'framer-motion';

export default function Hero() {
  return (
    <div className="relative flex flex-col w-full pb-20">
      {/* play mp4 video in imgs */}
      {/* <motion.video
        autoPlay
        muted
        loop
        className="w-full object-cover h-96"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <source src="/imgs/christmas-video.mp4" type="video/mp4" />
      </motion.video> */}
      {/* motion image */}
      <div className="absolute top-0 h-screen">
        <motion.img
          src="/imgs/christmas-hero.jpg"
          alt="Christmas Lights"
          className="min-w-[100vw] object-cover h-[100vh]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        />
      </div>

      {/* text over the center of the mp4 */}
      <div className="relative w-full h-screen flex items-center justify-center ">
        <div className="absolute inset-0 bg-gradient-to-t from-transparent to-black opacity-60 h-full"></div>
        <motion.div
          className="relative flex flex-col text-center justify-center items-center p-6 gap-6 w-full h-full"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          <div className="flex flex-col justify-center items-center md:flex-row gap-6 h-full">
            <motion.img
              src="/imgs/logo-offwhite.png"
              alt="Mulroony Christmas Lights"
              className="w-[39%] hidden lg:block px-9 py-3 lg:px-20 lg:py-9 bg-secondary rounded-lg"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
            />
            <motion.div
              className="flex flex-col justify-between items-center lg:max-w-[50%] xl:max-w-[35%]"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
            >
              <motion.h1
                className="text-4xl md:text-5xl font-bold text-white  drop-shadow-md"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 1 }}
              >
                Transform Your Home or Business into a Winter Wonderland
              </motion.h1>
              <div className="flex justify-center items-center">
                {' '}
                <motion.p
                  className="text-lg md:max-w-[60%] mt-4 text-white drop-shadow-sm font-semibold text-start pl-6"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 1.5 }}
                >
                  At Mulroony Christmas Lights, we specialize in professional
                  Christmas light installations that bring holiday cheer to
                  homes and businesses. With our attention to detail and
                  commitment to quality, your property will shine brighter than
                  ever this season.
                </motion.p>
              </div>
              <div className="pt-12">
                <motion.a
                  href="/contact"
                  className="bg-[#85312a] text-secondary px-6 py-3 rounded-lg mt-6 hover:bg-primary-dark text-2xl glow-effect md:max-w-[70%]"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.5, delay: 2 }}
                  whileHover={{ scale: 1.1 }}
                >
                  Get a free Quote!
                </motion.a>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

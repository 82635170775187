import React from 'react';
import { motion, useInView } from 'framer-motion';

interface ServiceSectionProps {
  title: string;
  items: string[];
  position?: 'start' | 'end';
  invertLights?: boolean;
  flickerDuration?: number; // New prop for flicker duration
}

const ServiceSection: React.FC<ServiceSectionProps> = ({
  title,
  items,
  position = 'start',
  invertLights = false,
  flickerDuration = 8, // Default duration
}) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true });

  const flickerAnimation = {
    opacity: [0.5, 1, 0.3, 0.8, 0.6, 1, 0.4, 1],
    transition: {
      duration: flickerDuration, // Increased duration for slower animation
      ease: 'easeInOut',
      repeat: Infinity,
      repeatType: 'loop' as 'loop',
    },
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        type: 'spring',
        stiffness: 100,
        staggerChildren: 0.2, // Staggering each child by 0.2 seconds
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={isInView ? 'visible' : 'hidden'}
      variants={containerVariants}
      className={`${
        position === 'start' ? 'timeline-start' : 'timeline-end'
      } timeline-box mb-4 bg-primary text-secondary border-0 border-b-2 ${
        position === 'start' ? 'border-l-2' : 'border-r-2'
      } border-[#85312a] px-3 md:px-4 lg:px-6 pb-4 md:pb-6 pt-6 md:pt-10 lg:pt-14 rounded-lg shadow-lg relative glow-effect`}
    >
      <motion.img
        src="imgs/lights.png"
        alt="christmas lights"
        className={`absolute -top-3 left-0 ${
          invertLights ? 'transform scale-x-[-1]' : ''
        }`}
        initial={{ opacity: 0.5 }}
        animate={flickerAnimation}
      />
      <p className="text-sm md:text-xl lg:text-2xl xl:text-3xl font-semibold py-2">
        {title}
      </p>
      <ul className="text-xs md:text-lg lg:text-xl list-none list-inside lg:px-3">
        {items.map((item, index) => (
          <motion.li key={index} variants={itemVariants}>
            {item}
          </motion.li>
        ))}
      </ul>
    </motion.div>
  );
};

export default ServiceSection;

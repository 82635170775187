import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Gallery: React.FC<{ backgroundColor?: string }> = ({
  backgroundColor,
}) => {
  const [selectedImg, setSelectedImg] = useState<string | null>(null);
  const { width } = useWindowSize(); // Get the current window width

  useEffect(() => {
    if (selectedImg) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = ''; // Enable scrolling
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [selectedImg]);

  const desktopBreakpoint = 1024; // You can adjust this value as needed

  const items = [
    { type: 'image', src: '/imgs/WORK1.jpg', alt: 'Asbestos Removal Project' },
    {
      type: 'text',
      content:
        'Transform your home with our <a href="https://mulroonychristmaslights.com/">professional Christmas light installation</a> services in NJ. Our insured and experienced team ensures a hassle-free holiday lighting setup, from rooftops to walkways. <a href="/contact">Contact us</a> today for custom-fitted installations that make your home the star of the neighborhood.',
    },
    { type: 'image', src: '/imgs/WORK2.jpg', alt: 'Lead Testing Site' },
    {
      type: 'text',
      content:
        'Enjoy a stress-free holiday season with our all-inclusive <a href="https://mulroonychristmaslights.com/">Christmas lights installation in New Jersey</a>. Our commercial-grade lighting and maintenance service ensure your display shines bright all season. Need lights on your roof, around windows, or along pathways? We’ve got you covered!',
    },
    { type: 'image', src: '/imgs/WORK3.jpg', alt: 'General Contracting Work' },
    { type: 'image', src: '/imgs/WORK4.jpg', alt: 'Completed Project' },
    {
      type: 'text',
      content:
        'Be the highlight in the Tri-state area with our custom holiday lighting. Our <a href="https://mulroonychristmaslights.com/">holiday light installers</a> handle everything, from design to safe installation and prompt removal. Book now for the best rates and create a festive display without the hassle.',
    },
    { type: 'image', src: '/imgs/WORK5.jpg', alt: 'Asbestos Testing' },
    { type: 'image', src: '/imgs/WORK6.jpg', alt: 'Lead Abatement' },
  ];

  const imageVariants = {
    hidden: { scale: 0.9, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: 'spring',
        duration: 1,
        bounce: 0.3,
      },
    },
  };

  const modalVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <section
      className="flex flex-col w-full justify-center items-center"
      style={{ backgroundColor: backgroundColor || 'transparent' }}
    >
      <div className="text-left sticky bg-primary text-secondary my-14 w-[95%] rounded-lg">
        <h2 className="text-[3rem] sm:text-[4rem] font-bold tracking-tight pr-10 w-full pl-2">
          Our Work
        </h2>
      </div>

      <div className="flex justify-center items-center max-w-full">
        <div className="flex flex-col justify-center items-center gap-4 w-full">
          {width < desktopBreakpoint ? (
            <ColumnLayout
              items={items}
              setSelectedImg={setSelectedImg}
              imageVariants={imageVariants}
            />
          ) : (
            <GridLayout
              items={items}
              setSelectedImg={setSelectedImg}
              imageVariants={imageVariants}
            />
          )}
        </div>
      </div>

      {selectedImg && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 rounded-lg"
          onClick={() => setSelectedImg(null)}
          initial="hidden"
          animate="visible"
          variants={modalVariants}
        >
          <motion.img
            src={selectedImg}
            alt="Enlarged work promo"
            className="max-w-3/4 max-h-3/4 rounded-lg"
            variants={imageVariants}
            initial="hidden"
            animate="visible"
          />
        </motion.div>
      )}
    </section>
  );
};

const ColumnLayout: React.FC<{
  items: any[];
  setSelectedImg: (img: string | null) => void;
  imageVariants: any;
}> = ({ items, setSelectedImg, imageVariants }) => (
  <div className="flex flex-col justify-center items-center gap-4 w-full">
    {items.map((item, index) =>
      item.type === 'image' ? (
        <AnimatedImage
          key={index}
          src={item.src}
          alt={item.alt}
          onClick={() => setSelectedImg(item.src)}
          variants={imageVariants}
        />
      ) : (
        <AnimatedText
          key={index}
          content={item.content}
          variants={imageVariants}
          svgColor="#FF0000" // Example color for SVGs
        />
      )
    )}
  </div>
);

const GridLayout: React.FC<{
  items: any[];
  setSelectedImg: (img: string | null) => void;
  imageVariants: any;
}> = ({ items, setSelectedImg, imageVariants }) => (
  <div className="grid grid-cols-3 gap-4">
    {items.map((item, index) =>
      item.type === 'image' ? (
        <AnimatedImage
          key={index}
          src={item.src}
          alt={item.alt}
          onClick={() => setSelectedImg(item.src)}
          variants={imageVariants}
        />
      ) : (
        <AnimatedText
          key={index}
          content={item.content}
          variants={imageVariants}
          svgColor="#85312a" // Example color for SVGs
        />
      )
    )}
  </div>
);

const AnimatedText: React.FC<{
  content: string;
  variants: any;
  backgroundColor?: string;
  svgColor?: string; // New prop for SVG color
}> = ({ content, variants, backgroundColor, svgColor = '#85312a' }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const maxFontSize = 18;
  const minFontSize = 16;
  const fontSize =
    content.length <= 20
      ? maxFontSize
      : Math.max(minFontSize, maxFontSize - (content.length - 20));

  const svgIcons = [
    '/svgs/christmas-wreath-1-svgrepo-com.svg',
    '/svgs/christmas-tree-svgrepo-com.svg',
    '/svgs/christmas-bell-free-illustrations-6-svgrepo-com.svg',
  ];

  // Helper function to generate random values for animation
  const getRandomTiming = () => ({
    duration: Math.random() * 8 + 6, // Random duration between 6 and 14 seconds
    delay: Math.random() * 2, // Random delay between 0 and 2 seconds
  });

  // Generate SVG patterns with random animation timings
  const generateSvgPatterns = () => {
    return svgIcons.map((icon, index) => {
      const { duration, delay } = getRandomTiming();

      return (
        <img
          key={index}
          src={icon}
          alt="Decorative SVG"
          className="absolute"
          style={{
            fill: svgColor,
            color: svgColor,
            width: '50px',
            height: '50px',
            opacity: 1,
            animation: `moveIn${index} ${duration}s linear ${delay}s infinite`,
          }}
        />
      );
    });
  };

  return (
    <motion.div
      ref={ref}
      className={`w-full px-8 py-8 aspect-square my-2 rounded-lg flex items-center justify-center relative overflow-hidden ${
        backgroundColor ? backgroundColor : 'bg-primary text-secondary'
      }`}
      initial="hidden"
      animate={controls}
      variants={variants}
    >
      <p
        style={{ fontSize: `${fontSize}px`, textAlign: 'start', zIndex: 10 }}
        dangerouslySetInnerHTML={{ __html: content }}
      ></p>
      {/* Repeating SVG Background */}
      <div className="absolute inset-0">{generateSvgPatterns()}</div>
      {/* Keyframes for SVG animations */}
      <style>{`
        @keyframes moveIn0 {
          from {
            transform: translateX(-100%) translateY(0);
          }
          to {
            transform: translateX(100%) translateY(50%);
          }
        }
        @keyframes moveIn1 {
          from {
            transform: translateX(100%) translateY(0);
          }
          to {
            transform: translateX(-100%) translateY(50%);
          }
        }
        @keyframes moveIn2 {
          from {
            transform: translateX(0%) translateY(-100%);
          }
          to {
            transform: translateX(0%) translateY(100%);
          }
        }
      `}</style>
    </motion.div>
  );
};

interface AnimatedImageProps {
  src: string;
  alt: string;
  onClick: () => void;
  variants: any;
}

const AnimatedImage: React.FC<AnimatedImageProps> = ({
  src,
  alt,
  onClick,
  variants,
}) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.img
      ref={ref}
      src={src}
      alt={alt}
      className="w-full aspect-square object-cover my-2 cursor-pointer rounded-lg"
      onClick={onClick}
      initial="hidden"
      animate={controls}
      variants={variants}
    />
  );
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export default Gallery;

import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import MenuItems from './menuItems';

// interface props
interface Props {
  menuIsOpen?: boolean;
  setMenuIsOpen?: Function;
}

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: '100%' },
};

export default function NavMenu(props: Props) {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(props.menuIsOpen!);

  useEffect(() => {
    setMenuIsOpen(props.menuIsOpen!);
  }, [props.menuIsOpen]);

  return (
    <motion.div
      initial={false}
      animate={props.menuIsOpen ? 'open' : 'closed'}
      variants={variants}
      className={`fixed ${
        !menuIsOpen ? 'hidden' : ''
      } left-0 top-0 bg-gray-200/50 w-full h-full} z-50 text-primary`}
    >
      <div className="flex flex-row justify-between h-full">
        {/* <div
          className="bg-gray-400/50 w-full h-auto"
          onClick={() =>
            props.setMenuIsOpen && props.setMenuIsOpen(!props.menuIsOpen)
          }
        ></div> */}
        <motion.div
          className="flex bg-gray-900/80 w-screen h-screen items-center justify-center text-start"
          onClick={() =>
            props.setMenuIsOpen && props.setMenuIsOpen(!props.menuIsOpen)
          }
        >
          <MenuItems col={true} />
        </motion.div>
      </div>
    </motion.div>
  );
}

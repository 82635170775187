import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import emailjs from '@emailjs/browser';

// Simple CSS Spinner
const Spinner = () => (
  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
);

const CustomForm = ({ apiUrl }: any) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    stateAndInstructions: '',
    selectedService: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [timer, setTimer] = useState(600); // 10 minutes in seconds

  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [timer]);

  const variants = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 0.6 } },
    hidden: { opacity: 0, translateY: 20 },
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const sendEmail = async (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);
    const templateParams = {
      name: formData.firstName.concat(' ', formData.lastName),
      email: formData.email,
      phone: formData.phone,
      inquiry: formData.stateAndInstructions,
    };

    try {
      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID!,
        process.env.REACT_APP_TEMPLATE_ID!,
        templateParams,
        process.env.REACT_APP_PUBLIC_KEY!
      );
      setIsSubmitted(true);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        stateAndInstructions: '',
        selectedService: '',
      });
    } catch (error) {
      console.error('Failed to send email:', error);
    }
    setIsSubmitting(false);
  };

  const debouncedSendEmail = debounce(sendEmail, 1000);

  return (
    <div className="flex justify-center items-center w-full">
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={variants}
        className="my-10 p-6 pb-10 bg-secondary rounded text-xl md:w-[50%] xl:w-[80%]"
      >
        <form
          onSubmit={(e: any) => {
            e.persist(); // This line allows the event to be used in an async function
            e.preventDefault(); // Prevent the default form submission behavior
            debouncedSendEmail(e);
          }}
        >
          <h2 className="font-bold mb-4 text-center">
            Start Your Service Here🎄
          </h2>
          {/* Form inputs remain unchanged */}
          <div className="mb-4">
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-black"
            >
              First name *
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              placeholder=""
              value={formData.firstName}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 bg-green-100 border border-gray-300 rounded-md shadow-sm focus:outline-none placeholder:text-slate-400"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="lastName"
              className="block text-sm font-medium text-black"
            >
              Last name *
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              placeholder=""
              value={formData.lastName}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 bg-green-100 border border-gray-300 rounded-md shadow-sm focus:outline-none placeholder:text-slate-400"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-black"
            >
              Email *
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder=""
              value={formData.email}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 bg-green-100 border border-gray-300 rounded-md shadow-sm focus:outline-none placeholder:text-slate-400"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="stateAndInstructions"
              className="block text-sm font-medium text-black"
            >
              Message *
            </label>
            <textarea
              id="stateAndInstructions"
              name="stateAndInstructions"
              placeholder=""
              value={formData.stateAndInstructions}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 bg-green-100 border border-gray-300 rounded-md shadow-sm focus:outline-none placeholder:text-slate-400"
              required
            ></textarea>
          </div>
          {/* phone */}
          <div className="mb-4">
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-black"
            >
              Phone
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder=""
              value={formData.phone}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 bg-green-100 border border-gray-300 rounded-md shadow-sm focus:outline-none placeholder:text-slate-400"
            />
          </div>

          {/* Customer Type Selection */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Are you a new or returning customer?
            </label>
            <div className="flex flex-col sm:flex-row sm:items-center mb-2">
              <label
                htmlFor="existingCustomer"
                className="flex items-center mb-2 sm:mb-0 sm:mr-4"
              >
                <input
                  type="radio"
                  id="existingCustomer"
                  name="customerType"
                  className="mr-2 leading-tight"
                />
                <span className="text-gray-700">
                  Existing Customer{' '}
                  <span className="bg-yellow-400 rounded-lg px-2 text-white">
                    30% off
                  </span>
                </span>
              </label>
              <label htmlFor="newCustomer" className="flex items-center">
                <input
                  type="radio"
                  id="newCustomer"
                  name="customerType"
                  className="mr-2 leading-tight"
                />
                <span className="text-gray-700">
                  New Customer{' '}
                  <span className="bg-indigo-400 rounded-lg px-2 text-white">
                    15% off
                  </span>
                </span>
              </label>
            </div>
          </div>

          {/* Discount Timer */}
          {/* <div className="mb-4 text-center">
            <p className="text-red-500 font-bold">
              20% off! Offer ends in:{' '}
              {`${Math.floor(timer / 60)}:${('0' + (timer % 60)).slice(-2)}`}
            </p>
          </div> */}

          <button
            type="submit"
            className={`w-full ${
              isSubmitted
                ? 'bg-secondary text-primary'
                : 'bg-primary text-secondary'
            } hover:bg-green-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline relative`}
            disabled={isSubmitting || isSubmitted}
          >
            {isSubmitting ? (
              <div className="w-full flex justify-center items-center">
                <Spinner />
              </div>
            ) : isSubmitted ? (
              'Submitted'
            ) : (
              'Send Message'
            )}
          </button>
        </form>
      </motion.div>
    </div>
  );
};

export default CustomForm;

import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Navbar from './components/navbar';
import Footer from './components/footer';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Phone from './components/phone';
import NotificationPopup from './components/NotificationPopup';

// Main Application Component
function App() {
  const svgBackground = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='64' height='64' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform='rotate(14)'%3E%3Crect width='100%25' height='100%25' fill='rgba(237, 236, 197, 1)'/%3E%3Ccircle cx='5' cy='25' r='1' fill='rgba(133, 49, 42, 1)'/%3E%3Ccircle cx='25' cy='15' r='1' fill='rgba(133, 49, 42, 1)'/%3E%3Ccircle cx='15' cy='20' r='2' fill='rgba(0, 58, 43, 1)'/%3E%3Ccircle cx='35' cy='20' r='2' fill='rgba(0, 58, 43, 1)'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' opacity='0.1' height='200%25' width='200%25'/%3E%3C/svg%3E`;

  return (
    <>
      <div
        className="bg-secondary font-lust overflow-x-hidden overflow-y-hidden scrollbar-hide"
        style={{
          backgroundImage: `url("${svgBackground}")`,
        }}
      >
        <Navbar />

        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/services" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
        <Phone />
        <NotificationPopup />
        <div className="pb-20 bg-primary">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;

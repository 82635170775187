import { useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';

interface Props {
  quote: string;
  author: string;
  review: string;
  direction?: 'left' | 'right';
  link: string;
}

const damping = 15;
const stiffness = 400;
const animationDuration = 1;

export default function Review(props: Props) {
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref, {
    margin: '-20%', // top, right, bottom, left
  });

  useEffect(() => {
    if (isInView) {
      controls.start({ opacity: 1, x: 0 });
    }
  }, [controls, isInView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial={{
        opacity: 0,
        x: props.direction && props.direction === 'left' ? -100 : 100,
      }}
      style={{
        opacity: 0,
        x: props.direction && props.direction === 'left' ? -100 : 100,
      }}
      transition={{
        duration: animationDuration,
        type: 'spring',
        stiffness: stiffness,
        damping: damping,
      }}
    >
      <blockquote className="flex h-full flex-col justify-between p-6 lg:p-12 gap-4 m-4 rounded-lg  bg-[#fffee1] text-left text-primary">
        <div>
          <div className="mt-4">
            <p className="text-2xl font-bold sm:text-3xl">{props.quote}</p>

            <p className="mt-4 leading-relaxed font-semibold">{props.review}</p>
          </div>
        </div>

        <div className="flex flex-row items-center justify-between">
          <footer className="text-lg font-semibold bg-secondary rounded-lg px-1">
            {props.author}
          </footer>
          <motion.a href={props.link} target="_blank" rel="noopener noreferrer">
            <svg
              className="w-8 h-8 bg-primary fill-current text-secondary glow-effect p-1 rounded-full"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M7 11v2.4h3.97c-.16 1.029-1.2 3.02-3.97 3.02-2.39 0-4.34-1.979-4.34-4.42 0-2.44 1.95-4.42 4.34-4.42 1.36 0 2.27.58 2.79 1.08l1.9-1.83c-1.22-1.14-2.8-1.83-4.69-1.83-3.87 0-7 3.13-7 7s3.13 7 7 7c4.04 0 6.721-2.84 6.721-6.84 0-.46-.051-.81-.111-1.16h-6.61zm0 0 17 2h-3v3h-2v-3h-3v-2h3v-3h2v3h3v2z"
                fill-rule="evenodd"
                clip-rule="evenodd"
              />
            </svg>
          </motion.a>
        </div>
      </blockquote>
    </motion.div>
  );
}

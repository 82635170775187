import React, { useEffect } from 'react';
import CustomForm from '../components/customForm';

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen flex justify-center items-center w-full pt-40">
      <div className="flex w-full justify-center items-center">
        <form className="w-full max-w-lg">
          <CustomForm />
        </form>
      </div>
    </div>
  );
}
